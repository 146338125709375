import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable()
export class NotificationsInterceptor implements HttpInterceptor {
  constructor(private notificationService: NzNotificationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse) {
          if (evt.body && evt.body.messages) {
            this.displayMessages(evt.body.messages);
          }
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          try {
            if (err.error.messages) {
              this.displayMessages(err.error.messages);
            } else {
              this.notificationService.error('Ошибка', err.error);
            }
          } catch (e) {
            this.notificationService.error('Ошибка', 'Возникла неизвестная ошибка');
          }
          //log error 
        }
        return of(err);
      }));
  }

  private displayMessages(messages: any[]) {
    for (let i = 0; i < messages.length; i++) {
      const
        message = messages[i],
        level = message.level;

      if (level === 0) {
        this.notificationService.success('Выполнено успешно', message.text);
      } else if (level === 1) {
        this.notificationService.info('Информация', message.text);
      } else if (level === 4) {
        this.notificationService.error('Ошибка', message.text);
      }
    }
  }
}
