export class BoardColumn {
  constructor(
    public readonly label: string,
    public readonly color: string
  ) {
  }
}

export class GlobalOptions {
  constructor(
    public readonly boardColumns: BoardColumn[]
  ) {
  }
}
