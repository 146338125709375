import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { OPTIONAL_CURRENT_USER_MAPPER } from './common/current-user.mapper';
import { BoardColumn, GlobalOptions } from './common/domain/global-options';

@Injectable({
  providedIn: 'root'
})
export class StartupService {

  constructor(private http: HttpClient) { }

  public getCurrentUser() {
    return this.http
      .get('api/account/me')
      .pipe(
        map(OPTIONAL_CURRENT_USER_MAPPER));
  }

  public getGlobalOptions() {
    return this.http
      .get('api/globals')
      .pipe(
        map((dto: any) => new GlobalOptions(
          dto.boardColumns.map((x: any) => new BoardColumn(x.label, x.color))
        )));
  }
}
