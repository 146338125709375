import { GlobalOptions } from '../domain/global-options';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root'})
export class GlobalOptionsStore {
  private _data: GlobalOptions | null = null;

  public setData(data: GlobalOptions) {
    if (this._data !== null) {
      throw new Error('Global options could only be set once');
    }

    this._data = data;
  }

  public getData(): GlobalOptions {
    if (this._data === null) {
      throw new Error('Global options were not set');
    }

    return this._data;
  }
}
